const documentation = () => {
  if (document.body.classList.contains('page-template-template-documentation')) {
    const main_tabs = document.querySelector('.documentation__tabs');
    const main_docs = document.querySelector('.documentation__docs');

    let main = []

    if (main_tabs) {
      main = main_tabs
    } else {
      main = main_docs
    }

    const documentation_tabs = main.querySelectorAll('.el-tab-pane');

    let documentation

    if (documentation_tabs) {
      documentation = documentation_tabs
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const idCategory = entry.target.getAttribute('id');
        const idMenu = `${idCategory}-menu`;
        const menuElement = document.getElementById(idMenu);

        if (entry.isIntersecting) {
          document.querySelectorAll('.documentation__single').forEach(el => el.style.opacity = 0.7);
          menuElement.style.opacity = 1;
        }
      });
    }, observerOptions);

    if(main_tabs) {
      documentation.forEach(e => {
        const documentation_menu = e.querySelectorAll('.documentation__single');

        if (window.innerWidth < 768) {
          e.addEventListener('click', function () {
            documentation_menu.forEach(s => {
              s.classList.toggle('hidden')
            })
          })
        }

        documentation_menu.forEach(s => {
          const idMenu = s.getAttribute('id');
          const idCategory = idMenu.replace('-menu', '');
          const elementToScroll = document.getElementById(idCategory);

          observer.observe(elementToScroll);

          s.addEventListener('click', () => {
            window.scrollTo({top: elementToScroll.offsetTop + main.offsetTop - 100, behavior: 'smooth'});
          });
        });
      });
    } else {
        const documentation_menu = main.querySelectorAll('.documentation__single');

        if (window.innerWidth < 768) {
          main.querySelector('.documentation__menu').addEventListener('click', function () {
            documentation_menu.forEach(s => {
              s.classList.toggle('hidden')
            })
          })
        }

        documentation_menu.forEach(s => {
          const idMenu = s.getAttribute('id');
          const idCategory = idMenu.replace('-menu', '');
          const elementToScroll = document.getElementById(idCategory);

          observer.observe(elementToScroll);

          s.addEventListener('click', () => {
            window.scrollTo({top: elementToScroll.offsetTop + main.offsetTop - 100, behavior: 'smooth'});
          });
        });
    }
  }
};

export default documentation;
