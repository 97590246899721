import MessageAlert from '../../../mixins/MessageAlert';
import Recaptcha from '../../../mixins/Recaptcha/Recaptcha.js';
import Loading from '../../../mixins/Loading';
import FormRules from '../../../mixins/Forms/FormRules';
export default {
  name: 'ContactForm',
  props: {
    id: {
      type: Number,
      default: 0
    },
    nonce: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  mixins: [Recaptcha, MessageAlert, Loading, FormRules],
  data: () => {
    return {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        province: '',
        agreement: false,
        agreement2: false,
        agreement3: false
      },
      send: false,
      provinceMap: {
        "pomorskie": "Pomorskie",
        "warminskomazurskie": "Warmińsko-Mazurskie",
        "kujawskopomorskie": "Kujawsko-Pomorskie",
        "mazowieckie": "Mazowieckie",
        "podlaskie": "Podlaskie",
        "lodzkie": "Łódzkie",
        "lubelskie": "Lubelskie",
        "podkarpackie": "Podkarpackie",
        "malopolskie": "Małopolskie",
        "swietokrzyskie": "Świętokrzyskie",
        "slaskie": "Śląskie",
        "opolskie": "Opolskie",
        "zachodniopomorskie": "Zachodniopomorskie",
        "lubuskie": "Lubuskie",
        "wielkopolskie": "Wielkopolskie",
        "dolnoslaskie": "Dolnośląskie"
      }
    };
  },
  computed: {
    agreements() {
      return this.$store.state.form_settings.agreements;
    },
    zones() {
      const groupedZones = this.$store.state.form_settings.zones.reduce((acc, zone) => {
        if (!acc[zone.name]) {
          acc[zone.name] = {
            name: zone.name,
            provinces: new Set(),
            mails: [],
            mail_from: zone.mail_from,
            thank: zone.thank
          };
        }
        acc[zone.name].provinces.add(zone.province);
        if (!acc[zone.name].mails.includes(zone.mail)) {
          acc[zone.name].mails.push(zone.mail);
        }
        return acc;
      }, {});
      return Object.values(groupedZones).map(zone => ({
        name: zone.name,
        provinces: [...zone.provinces],
        mails: zone.mails[0] || '',
        mail_from: zone.mail_from,
        thank: zone.thank
      }));
    },
    provinces() {
      return [...new Set(this.$store.state.form_settings.zones.map(zone => zone.province))];
    }
  },
  mounted() {
    this.setProvinceFromUrl();
  },
  methods: {
    setProvinceFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedZone = urlParams.get("zone");
      if (selectedZone) {
        const matchedZone = this.zones.find(zone => zone.name === selectedZone);
        if (matchedZone && matchedZone.provinces.length > 0) {
          this.form.province = matchedZone.provinces[0]; // Wybiera pierwsze województwo pasujące do strefy
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.Loading();
          await this.Recaptcha().then(async token => {
            const agree = [{
              text: this.agreements.agreement_1.replace(/(<([^>]+)>)/ig, ''),
              accepted: this.form.agreement
            }];
            if (this.agreements.agreement_2) {
              agree.push({
                text: this.agreements.agreement_2.replace(/(<([^>]+)>)/ig, ''),
                accepted: this.form.agreement2
              });
            }
            if (this.agreements.agreement_3) {
              agree.push({
                text: this.agreements.agreement_3.replace(/(<([^>]+)>)/ig, ''),
                accepted: this.form.agreement3
              });
            }

            // Znajdź wszystkie strefy, które zawierają wybrane województwo
            const matchingZones = this.zones.filter(zone => zone.provinces.includes(this.form.province));
            const matchingYouPage = this.zones.find(zone => zone.provinces.includes(this.form.province));
            const thankYouPage = matchingYouPage ? matchingYouPage.thank : null;

            // Pobierz nazwy stref
            const zoneNames = matchingZones.map(zone => zone.name);
            await this.$axios.post('/wp-json/api/form/contact', {
              id: this.id,
              name: this.form.name,
              province: this.form.province,
              zone: zoneNames,
              company: this.form.company,
              email: this.form.email,
              phone: this.form.phone,
              body: this.form.message,
              agreements: agree
            }, {
              headers: {
                Token: token
              }
            }).then(async response => {
              window.location.href = thankYouPage;
              this.send = true;
            }).catch(e => {
              this.MessageAlert({
                type: 'error',
                message: this.$t('form.notices.send_error'),
                duration: 0,
                showClose: true
              });
            });
          });
          this.Loading();
        } else {
          return false;
        }
      });
    }
  }
};